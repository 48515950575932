.App {
  margin-left: 40px;
}

.user {
  font-size: 12px;
}

a {
  color: white;
  text-decoration: none;
}

h1 {
  margin-top: 40px;
  margin-bottom: 40px;
}

button {
  margin-top: 8px;
  padding: 4px;
  border: 2px solid #c49991;
  border-style: rounded;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: #c49991;
}

input {
  margin-top: 6px;
  display: block;
}

.error {
  color: red;
  display: block;
  margin-top: 8px;
}
