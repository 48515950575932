.spinner {
  --spinSz: 28px; /* spinner size */
  box-sizing: border-box;
  display: inline-block;
  flex-shrink: 0;
  width: var(--spinSz);
  height: var(--spinSz);
  border-radius: 50%;
  border: calc(var(--spinSz) / 10) solid #1a31c54d;
  border-top-color: white;
  animation: 1s spin infinite linear;
  margin-left: 10px;
  vertical-align: middle;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
